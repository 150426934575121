import { useEffect, useRef } from "react";
import incode from "../incode.config";

export default function FrontId({ type, session, onSuccess, onError }) {
  const containerRef = useRef();
  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) {
      return;
    }
    
    const mType = type==="PASSPORT"? "passport" : ( type==="SSN"? "insuranceCard" : "front" );

    incode.renderCamera(mType, containerRef.current, {
      onSuccess,
      onError: onError,
      token: session,
      numberOfTries: -1,
      showTutorial: true,
      timeout: 50
    });

    isMounted.current = true;
  }, [onSuccess, onError, session, type]);

  return <div ref={containerRef} className="front-id"></div>;
}
