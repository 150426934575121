export default function Id({ ocr }) {
  let {
    name: { lastNameMrz, paternalLastName, firstName },
    gender,
    nationality,
    birthDate,
    documentNumber,
  } = ocr;

  const capitalize = str => { return str? str.charAt(0) + str.slice(1).toLowerCase() : ''; }
  const getBirthDate = birthDate => {
    if(birthDate) {
      const date = new Date(birthDate);
      const day = date.getDate() + 1;
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }
    return '';
  };

  return (
    <div className="id-data">
      <div className="id-bg">
        <div className="id-header">
          <div className="id-logo"></div>
          <div className="id-info">
            <div className="id-country"></div>
            <div className="id-type"></div>
          </div>
        </div>
        <div className="id-content">
          <div className="id-picture"></div>
          <div className="id-ocr">
            <div className="row">
              <div className="id-label ">
                <span className="title">Apellido</span>
                <span className="data">{capitalize(lastNameMrz? lastNameMrz : paternalLastName)}</span>
              </div>
              <div className="id-label">
                <span className="title">Nombre</span>
                <span className="data">{capitalize(firstName)}</span>
              </div>
            </div>
            <div className="row">
              <div className="id-label">
                <span className="title">Sexo</span>
                <span className="data">{gender}</span>
              </div>
              { nationality?
                <div className="id-label">
                  <span className="title">Nacionalidad</span>
                  <span className="data">{capitalize(nationality)}</span>
                </div>
                : null
              }
            </div>
            <div className="id-label">
              <span className="title">Fecha de nacimiento</span>
              <span className="data">{getBirthDate(birthDate)}</span>
            </div>
          </div>
        </div>
        <div className="id-footer ">
          <div className="id-label">
            <span className="title">Documento</span>
            <span className="data">{documentNumber}</span>
          </div>
          <div className="id-barcode "></div>
        </div>
      </div>
    </div>
  );
}
