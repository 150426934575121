import { useEffect, useContext } from "react";
import { OCRContext } from "../contexts/OCRProvider";
import incode from "../incode.config";
import Loading from "../containers/Loading";

export default function OCRData({ session, onSuccess, onError, skip }) {
  const { setOcr } = useContext(OCRContext);
  useEffect(() => {
    incode.ocrData({ token: session.token }).then(ocrData => {
      setOcr(ocrData);
      onSuccess();
    })
    .catch((er) => {
      onError();
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSuccess, onError, session, skip]);

  return <Loading />;
}
