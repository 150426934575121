export const getSession = async (account_id, holder, token) => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/customers/getBiometricSession/${account_id}/${holder}/${token}`);
  const json = await response.json();
  console.log('--->', json);
  if (json.error.code !== null) {
    //console.warn(json.error);
    return { status: "error", message: json.error.description || "Error", code: 403 };
  }

  console.log(json.data);

  return {
    status: "success",
    session: json.data
  };
};
