import IconButton from "../assets/icon-button.png";
import Error from "./Error";

import { useTranslation, Trans } from 'react-i18next';

/*
const handleIdentificationType = type => {
  switch (type) {
    case "PASSPORT":
      return "el pasaporte";
    case "NATIONALCARD":
      return "el documento de identidad";
    case "DRIVERSLICENSE":
      return "la licencia de conducir";
    case "SSN":
      return "el seguro social";
    default:
      return "el documento";
  }
};
*/

export default function Home({ goNext, customer, holder, error, errorData, deeplink }) {
  const { t } = useTranslation();

  if (error) return <Error data={errorData} deeplink={deeplink}/>;

  const { first_name, last_name, identification_type, identification_number, 
      joint_first_name, joint_last_name, joint_identification_type, joint_identification_number } = customer.account;

  const firstName = holder==='first'? first_name : joint_first_name;
  const lastName = holder==='first'? last_name : joint_last_name;
  const identificationType = holder==='first'? identification_type : joint_identification_type;

  const idNumber = holder==='first'? identification_number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : joint_identification_number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  return (
    <div className="home">
      <h1>{t('viewHome.title', { first_name: firstName, last_name: lastName })}</h1>
      <p>
        <Trans 
          i18nKey="viewHome.message" // optional -> fallbacks to defaults if not provided
          //defaults="hello <italic>beautiful</italic> <bold>{{what}}</bold>" // optional defaultValue
          values={{ identification_type: t('viewHome.'+identificationType.toLowerCase()), idNumber }}
          components={{ span: <span />, bold: <strong /> }}
        />
        <br/>
      </p>
      <span style={{ textAlign: 'left' }}>
        <Trans 
          i18nKey="viewHome.messageTips" // optional -> fallbacks to defaults if not provided
          //defaults="hello <italic>beautiful</italic> <bold>{{what}}</bold>" // optional defaultValue
          //values={{ identification_type: handleIdentificationType(identificationType), idNumber }}
          components={{ span: <span />, bold: <strong />, br: <br />, ul: <ul />, li: <li /> }}
        />
        <br/>
      </span>
      <button onClick={goNext /*</div>window.location.href = 'exp://192.168.0.59:19000/--/BiometricData?value=1&status=success&reasonMsg=OK'*/ }>
        <img src={IconButton} alt="icon"/>
        <span>{t('viewHome.start')}</span>
      </button>
    </div>
  );
}
