import { useEffect } from "react";
import Loading from "../containers/Loading";
import incode from "../incode.config";

export default function ProcessId({ session, onSuccess }) {
  useEffect(() => {
    incode.processId({ token: session.token }).then(() => {
      onSuccess();
    });
  }, [onSuccess, session]);

  return <Loading />;
}
