import WhatsappHelp from "./WhatsappHelp";

export default function Layout({ children }) {
  return (
    <div className="app-layout">
      <header className="header">
        <span className="logo"></span>
      </header>
      <main className="content">{children}</main>
      <footer className="footer">
        <p>
          Todos los derechos
          <br /> reservados 2022
        </p>
        <span className="logo"></span>
      </footer>
      <WhatsappHelp />
    </div>
  );
}
